

/* Basic Css  */

html,
body {
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden !important;
	font-weight: 400;
	transition: all .4s ease;
	-webkit-transition: all .4s ease;
	letter-spacing: 0.2px;
	background: white;
	font-family: 'Poppins', sans-serif;
}

:hover {
	transition: all .4s ease;
	-webkit-transition: all .4s ease;
}


button:focus {
	outline: none;
}
.btn-blue{
	background : var(--primary);
	color: white;
}
a {
	text-decoration: none;
}
.row{
	row-gap: 15px !important;
}

a:hover {
	text-decoration: none;
	color: #095d52;
}

section,
header,
footer {
	width: 100%;
	float: left;
}

.no-scroll {
	overflow: hidden !important;
}

.btn:hover {
	transition: all ease 0.4s;
}

::selection {
	color: #000;
	background: #fff;
}

.headersticky {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 99999;
	box-shadow: 0px 0px 5px 0 #cacaca;
	background: #fff;
}

.btn:focus {
	box-shadow: unset !important;
}

.form-control:focus,
.form-select:focus {
	box-shadow: unset !important;
	border-color: #000 !important;
}

:root{
    --primary:#00a8e8;
    --secondary: #2cbbf3;
    --button:#00a8e8;
    --dark: #00171f ;
    --white:#ffffff;
}


/* admin pages css start */

.admin-wrapper {
	display: flex;
	margin-bottom: 0px;
	min-height: 100dvh;
}

.sidebar-main-logo {
	text-align: center;
	padding: 50px 0px;
}

.sidebar-wrapper {
	width: 325px;
	background: #fff;
	padding: 0px;
	height: 100%;
	overflow-y: hidden;
	position: fixed;
	left: 0;
	top: 0px;
	z-index: 99;
	box-shadow: 5px 1px 11px 0px rgba(204,204,204,0.7);
	-webkit-box-shadow: 5px 1px 11px 0px rgba(204,204,204,0.7);
	-moz-box-shadow: 5px 1px 11px 0px rgba(204,204,204,0.7);
}

.sidebar-wrapper .flex-shrink-0 {
	padding-bottom: 100px;
	height: calc(100% - 125px);
	overflow-y: auto;
}

.admin-content-wrapper {
	width: calc(100% - 325px);
	margin-left: 325px;
	padding: 50px;
	margin-top: 15px;
	padding-bottom: 0px !important;
}

.user-img-sidebar {
	text-align: center;
	padding: 40px;
}

.user-img-sidebar img {
	height: 75px;
	width: 75px;
	border-radius: 80px;
	object-fit: cover;
}

.user-img-sidebar h3 {
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 0px;
	color: #000;
	font-weight: 600;
}

.sidebar-wrapper li {
	padding: 0;
}

.sidebar-wrapper li a {
	color: #000000;
	font-size: 14px;
	font-weight: 500;
	padding: 20px 30px;
	display: block;
}
.sidebar-wrapper li a:active{
	color: var(--secondary);
}

.sidebar-wrapper li.active {
    background: var(--secondary)
}

.sidebar-wrapper li.active a {
	color: #fff;
}

.sidebar-wrapper li img {
	height: 20px;
	margin-right: 10px;
}

.sidebar-wrapper li.active img {
	filter: brightness(0) invert(1);
}

.btn.sidebar-mb-toggle-btn img {
	height: 20px;
	object-fit: contain;
	filter: brightness(0) invert(1);
	margin-right: 5px;
}
.btngroup-table {}

.btngroup-table {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.btngroup-table form.text-start {
    margin-left: 8px;
}
.btn.sidebar-mb-toggle-btn {
	background: #165d51;
	height: 50px;
	color: #fff;
	font-size: 14px;
	width: 100vw;
	display: flex;
	align-items: center;
	border-radius: 0px;
	justify-content: center;
}

.sidebar-toggle {
	position: absolute;
	z-index: 999;
	top: 95px;
	left: 0px;
	display: none;
}

.update-pp-wrp {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: start;
}

.upt-pp-img img {
	height: 75px;
	width: 75px;
	object-fit: cover;
	border-radius: 100px;
}

.update-pp-content p {
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: 400;
	color: #000;
}

.update-pp-content {
	height: 100%;
	display: flex;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding-left: 25px;
	width: 100%;
}

.upt-edit-icon {
	background: #000;
	padding: 10px;
	border-radius: 50px;
	height: 45px;
	width: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.update-per-details-wrp {
	padding: 50px 0px;
}

.update-per-details-wrp label {
	color: #B9B9B9;
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 400;
}

.update-per-details-wrp input {
	height: 45px;
	background: #F5F5F5;
	border: 1px solid #E3E3E3;
	border-radius: 5px;
	margin-bottom: 15px;
	font-size: 14px;
}

.input-date {
	position: relative;
}

.input-date img {
	position: absolute;
	right: 13px;
	top: 13px;
	height: 20px;
	background: #f5f5f5;
	object-fit: contain;
}

.add-new-input {
	display: flex;
	align-items: center;
	justify-content: start;
}

.add-new-input a {
	white-space: nowrap;
}

.add-new-input a {
	white-space: nowrap;
	display: block;
	position: relative;
	top: -8px;
	font-size: 14px;
	padding-left: 15px;
	color: #0014FF;
}

.navigation.admin-nav {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
}

.btn.btn-grey-common {
    background: var(--button);
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid var(--secondary);
}

.btn.btn-grey-common:hover {
    background: #fff;
    color: var(--button);
    border: 1px solid var(--button);
}

.appointment-tabs .nav-item button {
	background: transparent;
	min-width: 125px;
	color: #000;
	border: 1px solid #707070;
	margin-right: 15px;
	margin-bottom: 15px;
	padding: 10px 50px;
	height: 45px;
	font-size: 14px;
}

.appointment-tabs .nav-item button.active {
	background: #fff;
	border-color: #fff;
	color: #000;
	box-shadow: -3px 5px 18px -1px rgba(161,161,161,0.61);
	-webkit-box-shadow: -3px 5px 18px -1px rgba(161,161,161,0.61);
	-moz-box-shadow: -3px 5px 18px -1px rgba(161,161,161,0.61);
}

.appointment-tabs .nav-item button:hover {
	background: #fff;
	color: #000;
	border-color: #fff;
	box-shadow: -3px 5px 18px -1px rgba(161,161,161,0.61);
	-webkit-box-shadow: -3px 5px 18px -1px rgba(161,161,161,0.61);
	-moz-box-shadow: -3px 5px 18px -1px rgba(161,161,161,0.61);
}

.common-table-wrapper {
	margin-top: 30px;
}

.appointment-tabs ul {
	margin-bottom: 0px;
}

.table-user {
	display: flex;
	align-items: center;
	justify-content: start;
}

.common-table-wrapper h5 {
	margin-bottom: 5px;
	font-size: 14px;
	color: #000;
	font-weight: 600;
	white-space: nowrap;
}

.common-table-wrapper h6 {
	margin-bottom: 5px;
	font-size: 14px;
	color: #000;
	font-weight: 500;
	line-height: 150%;
	white-space: nowrap;
}

.table-user img {
	height: 50px;
	width: 50px;
	object-fit: cover;
	margin-right: 15px;
	border-radius: 50px;
}

.common-table-wrapper p {
	margin-bottom: 0px;
	font-size: 13px;
	font-weight: 400;
	white-space: nowrap;
}

.common-table-wrapper td:last-child {
	text-align: right;
	white-space: nowrap;
}

.table-btn-group button {
	background: transparent;
	border: 2px solid #D1D1D1;
	padding: 0px 25px;
	font-size: 14px;
	height: 40px;
	margin-left: 15px;
	font-weight: 500;
}

.table-btn-group button:hover {
	background: var(--secondary);
	color: #fff;
}

.common-table-wrapper tr {
	vertical-align: middle;
	border-radius: 5px;
	border-bottom: 0px;
}

.common-table-wrapper tr td {
	border-bottom: unset !important;
	padding: 15px;
	background: aliceblue;
	margin-bottom: 10px;
	vertical-align: baseline;
}

.common-table-wrapper table {
	border-collapse: separate;
	border-spacing: 0 1em;
}

.btn-outline-danger {
	color: #F50C1A !important;
	border-color: #F50C1A !important;
}

.btn-outline-danger:hover {
	color: #fff !important;
	background: #F50C1A !important;
}

.table-btn-group {
	display: flex;
	justify-content: end;
	text-align: right;
}

.appointment-details-modal-wrp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 15px;
}

.appointment-deatils-box h1 {
	font-size: 18px;
	text-align: left;
	margin-bottom: 25px;
	color: #000;
}

.app-user-wrp img {
	height: 60px;
	width: 60px;
	border-radius: 60px;
	margin-right: 15px;
	object-fit: cover;
}

.app-user-wrp {
	text-align: left;
}

.appointment-details-modal-wrp h4 {
	font-size: 16px;
	margin-bottom:5px;
	font-weight: 500;
}

.appointment-details-modal-wrp h6 {
	font-size: 18px;
	margin-bottom: 0px;
	color: #D6D6D6;
	font-weight: 500;
}

.app-user-wrp {
	display: flex;
	justify-content: start;
	align-items: center;
}

.appointment-details-modal-wrp p {
	font-size: 14px;
	color: #aaa;
	margin-bottom: 5px;
	text-align: left;
}

.appointment-details-modal-wrp h3 {
	font-size: 16px;
	color: #000000;
	margin-bottom: 0px;
}

.app-agenda-box {
	text-align: left;
	background: #E4F7F3;
	padding: 25px;
	border-radius: 10px;
	margin-top: 15px;
	position: relative;
	height: 100%;
}

.app-agenda-box h3 {
	font-size: 16px;
	margin-bottom: 10px;
}

.app-agenda-box p {
	font-size: 14px;
	color: #B2B2B2;
	margin-bottom: 0px;
	line-height: 185%;
	text-align: justify;
}

.app-agenda-box a {
	position: absolute;
	right: 0;
}

.app-agenda-box a {
	position: absolute;
	right: 25px;
	font-size: 16px;
	color: #000AFF;
}

.app-agenda-box h2 {
	font-size: 24px;
	margin-top: 15px;
}

.modal-footer-btn-group {
	display: flex;
	justify-content: end;
	align-items: center;
	padding-top: 25px;
}

.modal-footer-btn-group button {
	background: transparent;
	border: 2px solid #D1D1D1;
	width: 135px;
	font-size: 14px;
	height: 40px;
	margin-left: 15px;
	font-weight: 500;
}

.modal-footer-btn-group button:hover {
	background: #000;
	border: 1px solid #000;
	color: #fff;
}

.wallet-recharge-wrp {
	background: #F2F9F7;
	padding: 15px;
	border-radius: 5px;
	box-shadow: -7px 12px 9px -8px rgba(199,199,199,0.75);
	-webkit-box-shadow: -7px 12px 9px -8px rgba(199,199,199,0.75);
	-moz-box-shadow: -7px 12px 9px -8px rgba(199,199,199,0.75);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.wallet-recharge-content img {
	background: #fff;
	padding: 15px;
	border-radius: 50px;
	height: 60px;
	object-fit: contain;
	margin-right: 25px;
}

.wallet-recharge-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.wallet-recharge-wrp h3 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 5px;
}

.wallet-recharge-content h6 {
	color: #8D8D8D;
	font-size: 16px;
	margin-bottom: 0px;
	font-weight: 300;
}

.wallet-rec-button button {
	background: #000;
	border: 1px solid #000;
	color: #fff;
	width: 135px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	border-radius: 4px;
}

.wallet-rec-button button:hover {
	color: #000;
	background: transparent;
}

.common-table-wrapper thead th {
	font-size: 14px;
	font-weight: 400;
	color: #000;
	padding: 10px;
	border-bottom: unset;
	white-space: nowrap;
}

.common-table-wrapper thead th img {
	margin-left: 5px;
}

.common-table-wrapper thead th img {
	height: 11px;
	cursor: pointer;
	object-fit: contain;
}

.action-btn-group button img {
	height: 12px;
	object-fit: contain;
}

.action-btn-group button {
	margin-left: 7.5px;
}

.action-btn-group {
	display: flex;
	align-items: center;
}

.logut-sidebar-wrp {
	height: 50px;
	background: white;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: start;
	padding-left: 30px;
	width: 100%;
}

.logut-sidebar-wrp a {
	color: #000;
	font-weight: 500;
	font-size: 14px;
}

.mobile-nav-toggle {
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 15px 25px;
	box-shadow: 0px 0px 5px 0 #cacaca;
	display: none;
}

.admin-mb-logo img {
	height: 60px;
	object-fit: contain;
}

.admin-sidebar-toggle-icon button img {
	height: 20px;
	object-fit: contain;
}

.admin-nav-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.admin-nav-search {
	position: relative;
	max-width: 300px;
}

.admin-nav-search.admin-navBig-search {
	max-width: 500px !important;
}

.admin-nav-search.admin-navBig-search input {
	border: 1px solid #707070;
}

.admin-nav-search button {
	position: absolute;
	right: 0;
	top: 0;
	background: #000;
	border-radius: 0px;
	height: 45px;
	width: 45px;
}

.admin-nav-search input {
	height: 45px;
	border-radius: 0px;
	padding-right: 75px;
	border: unset;
}

.admin-nav-search input::placeholder {
	font-size: 14px;
}

.admin-nav-search button img {
	height: 20px;
	object-fit: contain;
}

.admin-notify-bar ul {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	height: 100%;
}

.admin-notify-bar ul li {
	position: relative;
}

.admin-notify-bar ul li a {
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 45px;
	width: 45px;
	border-radius: 5px;
	margin-left: 30px;
}

.admin-notify-bar ul li a img {
	height: 20px;
	object-fit: contain;
}

.red-dot {
	background: #FF0000;
	height: 20px;
	width: 20px;
	display: block;
	border-radius: 20px;
	position: absolute;
	top: -8px;
	right: -8px;
	z-index: 9;
}

.admin-user-wrp img {
	height: 45px;
	width: 45px;
	object-fit: cover;
	border-radius: 10px;
	margin-right: 15px;
}

.admin-user-wrp {
	display: flex;
	align-items: center;
	justify-content: start;
}

.admin-user-wrp h3 {
	font-size: 18px;
	margin-bottom: 0px;
	color: #000;
	font-weight: 500;
}

.admin-user-wrp h5 {
	font-size: 16px;
	margin-bottom: 0px;
	color: #B5B5B5;
	font-weight: 500;
}

.admin-user-mb-show {
	display: none;
}

.admin-user-mb-show img {
	height: 45px !important;
	width: 45px !important;
	object-fit: cover;
}

.admin-common-title h1 {
	font-size: 18px;
	font-weight: 500;
	color: #000;
	margin-bottom: 20px;
}

.admin-white-box {
	background: #fff;
	padding: 15px 0px;
	border-radius: 10px;
	position: relative;
	margin-bottom: 30px;
}

.common-table-wrapper .blue-line {
	background: #060098 !important;
	height: 100%;
	width: 7.5px;
	padding: 0 !important;
}

.common-table-wrapper .yellow-line {
	background: #CEB627 !important;
	height: 100%;
	width: 7.5px;
	padding: 0 !important;
}

.common-table-wrapper .orange-line {
	background: #D10000 !important;
	height: 100%;
	width: 7.5px;
	padding: 0 !important;
}

.common-table-wrapper .purple-line {
	background: #8E00A0 !important;
	height: 100%;
	width: 7.5px;
	padding: 0 !important;
}

.btn.black-fill {
    background: var(--button);
    color: #fff;
    border-color: var(--button);
}

.btn.black-fill:hover {
    background: transparent;
    color: var(--button);
    border-color: var(--button);
}

.active-status-wrp ul {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.active-status-wrp ul li button {
	border: 1px solid #707070;
	font-size: 14px;
	padding: 8px 36px;
	border-radius: 25px;
	background: transparent;
	color: #707070;
}

.active-status-wrp ul li button.active {
	background: #6D892F;
	color: #fff;
	border-color: #6D892F;
}

.active-status-wrp ul li button.online.active {
	background: #6D892F;
	color: #fff;
	border-color: #6D892F;
}

.active-status-wrp ul li button.busy.active {
	background: #CEB600;
	color: #fff;
	border-color: #CEB600;
}

.active-status-wrp ul li button.unavailabe.active {
	background: #D10000;
	color: #fff;
	border-color: #D10000;
}

.active-status-wrp ul li {
	border-right: 1px solid #cacaca;
	width: 33.3%;
	text-align: center;
}

.active-status-wrp ul li:last-child {
	border-right: unset !important;
}

.accepted-tr td {
	background-color: #91FFD0 !important;
}

.cancelled-tr td {
	background-color: #FFDFDF !important;
}

.pending-tr td {
	background-color: #F7F8FA !important;
}

.sep-replicated {
	display: flex;
	align-items: start;
}

.cutted-text {
	margin-right: 15px;
}

.cutted-text h6 {
	opacity: 0.4;
	text-decoration: line-through;
}

.tabs-flex-wrp {
	display: flex;
	align-items: start;
}

.cb-box-wrp {
	background: #fff;
	padding: 25px;
	margin-bottom: 30px;
	border-radius: 4px;
}

.cb-box-wrp h2 {
	color: #00D341;
	font-size: 18px;
	margin-bottom: 5px;
}

.cb-box-wrp h6 {
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: 400;
}

.border-dotted-right {
	border-right: 2px dotted #cacaca;
	padding-right: 50px;
	margin-right: 50px;
}

.update-audio-video {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.update-audio-video h4 {
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: 400;
	color: #000;
}
.update-audio-video button {
	border: 1px solid #E3E3E3;
	padding: 15px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	background: #F5F5F5;
	width: 200px;
	margin: 0 auto;
}

.update-audio-video button img {
	margin-right: 25px;
	width:15px;
	object-fit: contain;
}

.admin-notify-bar ul li.active a {
	background: #000;
}

.admin-notify-bar ul li.active a img {
	filter: brightness(0) invert(1);
}

/* client admin css start */

.filter-dropdown-admin select {
	width: 250px;
	height: 45px;
	margin-top: 15px;
	background: #fafafa;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 20px;
	padding-right: 15px;
	background-position: calc(100% - 10px) 50%;
	border: 1px solid #eee;
	font-size: 14px;
}

.archive-ul {
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;
	margin-left: 15px;
}

.admin-title-flex {
	display: flex;
	align-items: baseline;
	justify-content: start;
}

.admin-title-flex h3 {
	font-size: 20px;
	margin-bottom: 10px;
	color: #000;
	font-weight: 500;
	white-space: nowrap;
}

.archive-ul li {
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 10px;
}

.archive-ul li a {
	color: #909090;
	border: 1px solid #cacaca;
	font-weight: 400;
	display: block;
	font-size: 12px;
	padding: 4px 24px;
	border-radius: 4px;
	cursor: pointer;
	background: transparent;
}

.archive-ul li.active a {
	color: #fff;
	border: 1px solid #000;
	background: #000;
}

.admin-title-header {
	margin-top: 40px;
}

.basic-info-header {
	background: aliceblue;
	margin-top: 15px;
	margin-bottom: 15px;
	padding: 10px 20px;
}

.basic-info-header h5 {
	margin-bottom: 0px;
	font-size: 18px;
	font-weight: 500;
}

.form-fields-row {
	padding: 15px 20px;
}

/* .form-fields-row .row {
	align-items: center;
} */

.form-fields-row h3 {
	font-size: 14px;
	margin-bottom: 0px;
	color: #000;
}

.form-fields-row input.form-control {
	background: #FBFBFB;
	font-size: 12px;
	height: 40px;
	border: 1px solid #E3E3E3;
	font-weight: 400;
	color: #333;
}

.form-fields-row textarea.form-control {
	background: #FBFBFB;
	font-size: 12px;
	border: 1px solid #E3E3E3;
	font-weight: 400;
	color: #333;
}

.form-fields-row input::placeholder {
	color: #B9B9B9;
}

.form-fields-row textarea::placeholder {
	color: #B9B9B9;
}

.form-fields-row select {
	width: 100%;
	height: 40px !important;
	background: #fafafa;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 20px;
	padding-right: 15px;
	background-position: calc(100% - 10px) 50%;
	border: 1px solid #eee;
	font-size: 12px;
	color: #000;
}

.form-fields-row a {
	font-size: 14px;
	color: #004EFF;
	font-weight: 400;
}

.form-fields-row .form-check-input:checked {
	border-color: #717171;
}

.form-check.form-switch label {
	font-size: 16px;
	color: #717171;
	line-height: 140%;
	font-weight: 400;
	padding-left: 10px;
}

.company-nav-buttons {
	display: none;
}

.table-btn-group button:hover img {
	filter: brightness(0) invert(1);
}
.newletter-table.common-table-wrapper td:last-child {
    text-align: left;
}
.word-break {
	white-space: inherit !important;
}

.common-table-wrapper h6 small {
	color: #1BB49C;
	font-size: 12px;
}

.common-table-wrapper h6 img {
	margin-left: 5px;
	height: 12px;
	object-fit: contain;
}

.common-table-wrapper tr td a {
	font-size: 14px;
	color: #0058FF;
	/* border-bottom: 2px solid #0058FF; */
	white-space: nowrap;
}

.red-badge {
	background: red;
	padding: 2px 4px;
	border-radius: 4px;
	color: #fff;
	font-size: 10px;
}

.result-text {
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 500;
}

.admin-filter-navbar {
	padding-top: 25px;
}

.admin-filter-navbar {
	padding-top: 25px;
}

.filter-select-box select {
	width: 100%;
	height: 40px;
	background: #fafafa;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 20px;
	padding-right: 15px;
	background-position: calc(100% - 10px) 50%;
	border: 1px solid #eee;
	font-size: 12px;
	color: #000;
}

.filter-select-box label {
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 500;
}

.filter-switch-box {
	display: flex;
	align-items: end;
	height: 100%;
}

.filter-buttons-wrp ul {
	margin-bottom: 0px;
	display: flex;
	align-items: end;
	justify-content: end;
	padding-left: 0px;
	list-style: none;
	height: 100%;
}

.filter-switch-box label {
	font-size: 14px !important;
	color: #000 !important;
	font-weight: 600 !important;
}

.filter-buttons-wrp ul li button {
	background: transparent;
	margin-right: 15px;
	color: #000000;
	font-size: 14px;
	padding: 7.5px 15px;
	font-weight: 400;
	border: 2px solid #E5E7E9;
	white-space: nowrap;
}

.filter-buttons-wrp ul li button:hover {
	background: #E5E7E9;
}

.filter-buttons-wrp {
	height: 100%;
}

.common-wizard ul.nav {
	position: relative;
	justify-content: space-between;
	width: 50%;
	margin: 0 auto;
}

.common-wizard ul.nav::after {
	content: "";
	width: 85%;
	border: 1px dashed #000;
	height: 1px;
	position: absolute;
	top: 28%;
	left: 12%;
}

.common-wizard .nav-item button {
	color: #000;
}

.common-wizard .nav-item button.active {
	background: initial;
	color: #000;
}

.common-wizard ul.nav .nav-item button {
	text-align: center;
}

.common-wizard ul.nav .nav-item button.active span{
	background: #000;
	color: #fff;
}

.common-wizard ul.nav .nav-item button span {
	background: #fff;
	border: 1px solid #000;
	width: 45px;
	height: 45px;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 99;
	border-radius: 40px;
	font-size: 16px;
	font-weight: 600;
	margin: 0 auto;
}

.form-fields-row p {
	font-size: 14px;
	color: #B9B9B9;
	margin-bottom: 0px;
}

.wizard-internal-content {
	padding: 0px 50px;
}

.table-checkbox input {
	height: 20px;
	width: 20px;
}

.select-flex {
	display: flex;
	align-items: center;
}

.common-table-wrapper .form-select {
	font-size: 12px;
	width: 150px;
	height: 40px;
	margin-right: 10px;
}

.info-icon-table {
	height: 20px !important;
	opacity: 0.60;
}

.wizard-tt-title {
	font-size: 14px;
	margin-bottom: 0px;
	color: #000;
}

.filter-input-box input {
	width: 100%;
	height: 40px;
	background: #fafafa;
	border: 1px solid #eee;
	font-size: 12px;
	color: #000;
}

.filter-input-box label {
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 500;
}

.filter-input-box input::placeholder {
	font-size: 12px;
	color: #000;
}

.filter-input-box {
	position: relative;
}

.filter-input-box img {
	position: absolute;
	right: 13px;
	bottom: 13px;
	height: 12.5px;
	cursor: pointer;
	object-fit: contain;
}

.customer-field-wrapper .row {
	align-items: baseline;
}

.customer-field-wrapper {
	background: #F5F5F5;
	padding: 25px;
	border: 1px solid #E3E3E3;
	border-radius: 5px;
}

.form-fields-row small {
	font-size: 12px;
	color: #000;
	padding-top: 5px;
}

.customer-field-wrapper label {
	font-size: 12px;
	padding-bottom: 5px;
}

.invoice-overview-wrp {
	background: #fff;
}

.invoice-overview-wrp {
	background: #fff;
	margin-top: 25px;
}

.invoice-overview-wrp .io-head {
	background: #E2E2E2;
	padding: 15px;
}

.invoice-overview-wrp .io-head {
	background: #E2E2E2;
	padding: 10px;
}

.invoice-overview-wrp .io-head h3 {
	font-size: 16px;
	margin-bottom: 0px;
	color: #000;
	font-weight: 500;
}

.invoice-ov-content {
	padding: 25px;
}

.invoice-ov-content ul {
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.invoice-ov-content ul li {
	display: flex;
	align-items: center;
}

.io-dot {
	width: 25px;
	height: 25px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
}

.io-dot span {
	width: 15px;
	height: 15px;
	display: block;
	border-radius: 15px;
}

.io-dot.black {
	background: #E8E8E8;
}

.io-dot.black span {
	background: #000;
}

.io-dot.grey {
	background: #E8E8E8;
}

.io-dot.grey span {
	background: #909090;
}

.io-dot.blue {
	background: #E0EAFD;
}

.io-dot.blue span {
	background: #1163FB;
}

.io-dot.yellow {
	background: #FFF9D0;
}

.io-dot.yellow span {
	background: #F8DE31;
}

.io-dot.red {
	background: #FFDBDB;
}

.io-dot.red span {
	background: #FD2A2A;
}

.io-dot.green {
	background: #CDFFCC;
}

.io-dot.green span {
	background: #24C421;
}

.invoice-ov-content ul li h6 {
	font-size: 15px;
	margin-bottom: 0px;
	font-weight: 500;
}

.invoice-ov-content ul li p {
	font-size: 13px;
	margin-bottom: 0px;
	font-weight: 400;
}

.btn.view-btn-tb {
	background: #000;
	font-size: 10px;
	color: #fff;
	padding: 5px 15px;
	font-weight: 500;
	letter-spacing: 0.2px;
}

.invoice-ud-head {
	background: #7FDED2;
	padding: 15px;
	margin-bottom: 15px;
}

.invoice-ud-head h3 {
	font-size: 18px;
	color: #000;
	font-weight: 500;
	margin-bottom: 0px;
}

.common-table-wrapper .form-check input {
	height: 25px;
	width: 25px;
	border-radius: 0px;
	border: 2px solid #707070;
}

.common-table-wrapper .form-check-input:checked {
	background-color: #000;
	border-color: #000;
	box-shadow: unset !important;
}

.invoice-user-details-wrp {
	padding: 25px;
}

.invoice-ud-table {
	border: 2px dashed #000;
	margin-bottom: 15px;
}

.invoice-ud-table .common-table-wrapper thead th {
	background: #EFEFEF;
	color: #000;
}

.invoice-ud-table .common-table-wrapper tbody tr td {
	background: #fff;
}

.invoice-ud-table  .common-table-wrapper table {
	border-spacing: 0 0;
}

.invoice-ud-table .table-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background: #DEDEDE;
}

.invoice-ud-table .table-head h3 {
	font-size: 16px;
	margin-bottom: 0px;
	color: #000;
}

.invoice-ud-table .table-head a img {
	height: 15px;
	object-fit: contain;
}

.add-ff-link {
	float: left;
	text-decoration: none !important;
	border: unset !important;
}

.add-ff-link i {
	padding-right: 10px;
}

.table-border-top td {
	border-top: 2px solid #707070;
}

.invoice-total-wrp {
	background: #EFEFEF;
	padding: 25px;
	margin-top: 30px;
}

.invoice-total-wrp h3 {
	font-size: 18px;
	margin-bottom: 15px;
	font-weight: 500;
}

.inv-stat-row {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	padding: 5px 15px;
}

.inv-stat-row p {
	color: #A3A3A3;
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 400;
}

.inv-stat-row h5 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0px;
}

.invoice-total-stats {
	border: 2px dashed #707070;
	padding: 15px 0px;
	border-radius:10px;
}

.inv-stat-row.border-top {
	border-color: #000 !important;
	padding-top: 10px;
	margin-top: 10px;
}

.cancel-submit-btn-group {
	display: flex;
	align-items: center;
	justify-content: end;
	max-width: 400px;
	width: 100%;
	margin-left: auto;
	padding-top:15px ;
	padding-bottom: 25px;
}

.funds-nav-buttons {
	display: none;
}

.preview-email-head h4 {
	font-size: 16px;
}

.preview-email-head h6 {
	font-size: 12px;
	margin-bottom: 2.5px;
}

.preview-email-head a {
	font-size: 12px;
	color:#0031FF;
}

.preview-email-head {
	padding-top: 15px;
}

.email-sample-demo {
	background: #eee;
	padding: 35px;
	margin-top: 25px;
	border: 2px dashed #333;
	margin-right: 30px;
	position: relative;
	overflow: hidden;
}

.email-sample-demo h2 {
	font-size: 20px;
}

.email-sample-demo h5 {
	font-size: 14px;
}

.email-sample-demo h4 {
	font-size: 18px;
}

.email-sample-demo h1 {
	font-size: 36px;
}

.email-sample-demo button {
	background: #8E97FF;
	width: 175px;
	height: 45px;
	color: #000;
	font-weight: 600;
	margin-bottom: 25px;
}

.email-sample-demo p {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0px;
}

.sample-text {
	position: absolute;
	top: 33%;
	font-size: 75px;
	transform: rotate(-45deg);
	left: 28%;
	opacity: 0.5;
	text-transform: uppercase;
}

.statement-wrp h3 {
	font-size: 16px;
	margin-bottom: 0px;
}

.inv-stat-text-row {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	padding: 5px 0px;
}

.invoice-statement-box {
	background: #F2F4F7;
	padding: 25px;
	margin-top: 25px;
	border-radius: 5px;
}

.inv-stat-text-row p {
	margin-bottom: 0px;
	font-size: 14px;
	font-weight: 500;
}

.inv-stat-text-row p i {
	background: #E3E3E3;
	padding: 10px;
	border-radius: 20px;
	align-items: center;
	margin-right: 10px;
}

.inv-stat-text-row p span {
	margin-left: 15px;
}

.invoice-statement-box label {
	font-size: 14px;
	font-weight: 500;
}

/* admin panel css start */

.sidebar-wrapper li.active button {
	color: #fff;
}

.sidebar-wrapper li button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 17px 30px;
    font-size: 14px;
    font-weight: 500;
}


.sidebar-wrapper li .btn-toggle-nav {
	background: var(--primary);
    padding: 7px 30px;
}

.sidebar-wrapper li .btn-toggle-nav {
	list-style: none;
	margin-bottom: 0px;
}

.sidebar-wrapper li .btn-toggle-nav li {
	padding: 5px 35px;
	position: relative;
}

.sidebar-wrapper li .btn-toggle-nav li a {
	font-weight: 400;
	font-size: 12px;
	font-style: italic;
	color: white;
	padding: 7px 30px;

}

.sidebar-wrapper li .btn-toggle-nav li::before {
	content: "";
	height: 2px;
	width: 8px;
	position: absolute;
	background: var(--white);
	top: 18px;
	left: 15px;
}

.sidebar-wrapper li.active .btn-toggle-nav li::before { 
	background: #fff;
}

.sidebar-wrapper li .btn-toggle-nav li.active a {
	color: #00FF76;
}

.sidebar-wrapper li button.collapsed i {
	transform: rotate(0deg);
	transition: all ease 0.4s;
}
.sidebar-wrapper li button i {
	transform: rotate(180deg);
	transition: all ease 0.4s;
}

.admin-filter-navbar.bg-grey {
	padding: 15px;
	background: aliceblue;
	margin-top: 25px;
}

.action-btn-group a {
	border: unset !important;
}

.filter-dropdown-admin h6 {
	margin-bottom: 0px;
	margin-right: 25px;
	font-size: 14px;
}

.filter-input-box label {
	white-space: nowrap;
	padding-right: 10px;
	margin-bottom: 0px;
}

.black-badge {
	background: #000;
	color: #fff;
	font-size: 12px;
	padding: 3px 9px;
	border-radius: 5px;
	text-align: center;
}

.table-btn-group a {
	border-bottom: unset !important;
}

.btn-close.reject-req-close {
	position: absolute;
	top: 15px;
	right: 15px;
}

.reject-req-modal {
	padding: 50px;
}

.reject-req-modal h3 {
	font-size: 20px;
	margin-bottom: 25px;
	color: #000;
	font-weight: 500;
}

.reject-req-modal textarea {
	background: #fafafa;
	font-size: 14px;
}

.reject-req-modal button {
	background: #000;
	width: 100%;
	margin-top: 25px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 14px;
}

.legal-badge-list {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}

.legal-badge-list li {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}

.legal-badge-list li span {
	font-size: 12px;
	background: #E0E0E0;
	padding: 3px 9px;
	border-radius: 4px;
	color: #000;
	font-weight: 500;
}

.legal-badge-list li a {
	font-size: 12px !important;
	border-bottom: unset !important;
}



.login-page input[type="email"], .login-page input[type="password"]
{
 background-color:#FBFAFA;
 border: 1px solid #F2F2F2;
}
.login-page .login-form
{
	padding: 30px 50px 50px 50px;
}
.login-page .login-btn
{
  background-color: black;
  padding:16px;
  color:white;
}
.login-page .logo-login
{
 height: auto;
}
.auth-modal-artwork
       {
         background: #E4F7F3;
      padding: 100px 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
       }


	   .pagination .page-item.active .page-link{
		background-color:#000 !important;
		color: #fff !important;	
		border: none;
	
	}
	.pagination .page-link 
	{
		color: #000;
	}
	
.custm-loader {
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f0f2f51a;
	color:#000000 !important;
	backdrop-filter: blur(2px);
	overflow: hidden;
	/* padding-right: 230px; */
  }
  

  .sidebar-wrapper li:has(.show) button {
    color: #fff;
    background-color: var(--secondary);
}