.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.special-label {
  display: none;
}

.form-fields-row select{
  height: 20px !important;
}

.PhoneInput {
  background: transparent !important;
  display: flex  !important;
}
.PhoneInput .PhoneInputInput {
  border: none;
  outline: none;
  box-shadow: none;
}
.sidebar-wrapper .btn-toggle-nav li.active a {
  color: #ffffff !important;
}


.change-item {
  margin-bottom: 10px;
  border: 2px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.change-field {
  font-weight: bold;
  color: #333;
}

.change-value {
  color: #666;
}

.plain-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
